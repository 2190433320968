import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomModal from "../../../components/Common/CustomModal";
import Tooltip from "@mui/material/Tooltip";
import { MESSAGES } from "../../../constants";
import { socket } from "../../../services/Socket";
import {
  AXIOS_INSTANCE,
  DATA_PRODUCT_INTERACTION_MAP_API,
} from "../../../store/apiUtils/config";

const LVTUseCaseSelectorModal = ({
  useCasesArray,
  setUseCasesArray,
  domainId,
  teamSpaceId,
  lvtUseCasesModal,
  lvtUseCasesModalToggle,
}) => {
  const [selectedUseCase, setSelectedUseCase] = useState("");
  const history = useHistory();

  const handleChange = useCallback(
    (event) => {
      setSelectedUseCase(event.target.value);
    },
    [setSelectedUseCase]
  );

  useEffect(() => {
    const getData = async () => {
      if (domainId) {
        const { data } = await AXIOS_INSTANCE.get(
          `${DATA_PRODUCT_INTERACTION_MAP_API}/getRelatedUsecases?domainId=${domainId}`
        );
        setUseCasesArray(data.data.data);
      } else if (teamSpaceId) {
        socket.emit("getTeamSpaceRelatedUseCases", { teamSpaceId });
        socket.on("recieveTeamSpaceRelatedUseCases", (res) => {
          setUseCasesArray(res.data);
        });
      }
    };
    getData();
  }, [lvtUseCasesModal]);

  const navigateToUseCaseDPIM = useCallback(() => {
    if (selectedUseCase !== "") {
      lvtUseCasesModalToggle();
      const urlParam = teamSpaceId
        ? `teamSpaceId=${teamSpaceId}`
        : `domainId=${domainId}`;
      history.push(
        `/dataproduct-interaction-map?useCaseId=${selectedUseCase}&${urlParam}`
      );
    }
  }, [lvtUseCasesModalToggle, history, selectedUseCase, domainId, teamSpaceId]);

  const closeModal = useCallback(() => {
    setSelectedUseCase("");
    lvtUseCasesModalToggle();
  }, [lvtUseCasesModalToggle, setSelectedUseCase]);

  return (
    <CustomModal
      displayState={lvtUseCasesModal}
      toggleState={lvtUseCasesModalToggle}
      title="Select Use Case"
      primaryBtn="Select Use Case"
      primaryBtnHandler={navigateToUseCaseDPIM}
      secondaryBtn="Cancel"
      secondaryHandler={closeModal}
      formId="lvt-usecase-selection-modal"
    >
      <div className="lvt-usecase-modal" data-testid="usecase-modal">
        <div className="lvt-usecase-modal-text">
          Select which Use Case you want to design the Data Product Interaction
          Map for. Note that you will always be able to switch to other Use
          Cases without losing progress.
        </div>
        <div className="lvt-usecase-modal-sub-heading">Use Case&#40;s&#41;</div>
        <Select
          value={selectedUseCase}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          size="small"
          data-testid="usecase-select"
        >
          <MenuItem value="" sx={{ display: "none" }}>
            Select Use Case
          </MenuItem>
          {useCasesArray?.map((useCase) =>
            useCase.isLVTComplete ? (
              <MenuItem key={useCase.id} value={useCase.id}>
                {useCase.description}
              </MenuItem>
            ) : (
              <Tooltip
                key={useCase.id}
                title={MESSAGES.LVT_CONNECTION_NOT_COMPLETED_FOR_USECASE}
                arrow
                placement="top"
              >
                <MenuItem
                  key={useCase.id}
                  value={useCase.id}
                  style={{ cursor: "not-allowed", color: "rgba(0,0,0,0.5)" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {useCase.description}
                </MenuItem>
              </Tooltip>
            )
          )}
        </Select>
      </div>
    </CustomModal>
  );
};

LVTUseCaseSelectorModal.propTypes = {
  useCasesArray: PropTypes.array,
  setUseCasesArray: PropTypes.func,
  domainId: PropTypes.string,
  teamSpaceId: PropTypes.string,
  lvtUseCasesModal: PropTypes.bool,
  lvtUseCasesModalToggle: PropTypes.func,
};

export default LVTUseCaseSelectorModal;
