import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { Amplify } from "aws-amplify";
import ReactGA from "react-ga4";

// Import Routes all
import { userRoutes, authRoutes, maintenanceRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";
import awsconfig from "./aws-exports";

// Import scss
import "./assets/scss/theme.scss";

// Google Tag Manager
import { usePageTracking } from "./helpers/CustomHooks/GTM/usePageTracking";

Amplify.configure(awsconfig);

const gtmId = process.env.REACT_APP_GTM_ID;
ReactGA.initialize(gtmId);

const App = () => {
  function getLayout() {
    return HorizontalLayout;
  }

  const Layout = getLayout();

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  usePageTracking();

  const NonAuthmiddleware = ({
    component: Component,
    layout: LayoutWrapper,
  }) => (
    <Route
      render={(props) => {
        return (
          <LayoutWrapper>
            <Component {...props} />
            {process.env.REACT_APP_MAINTENANCE_PAGE_ENABLE === "true" ? <Redirect to="/" /> : ""}
          </LayoutWrapper>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      {process.env.REACT_APP_MAINTENANCE_PAGE_ENABLE === "true" ? <Router>
        {maintenanceRoutes.map((route, idx) => (
            <NonAuthmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
            />
          ))}
      </Router> :
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
            />
          ))}

          {userRoutes.map((route, idx) => {
            if (route.submenu && route.submenu.length) {
              route.submenu.map((link, idx1) => {
                return (
                  <Authmiddleware
                    path={link.path}
                    layout={Layout}
                    component={link.component}
                    key={idx1}
                    exact={link.exact}
                  />
                );
              });
            } else {
              return (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  exact={route.exact}
                />
              );
            }
            return null;
          })}
        </Switch>
      </Router>
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object.isRequired,
  component: PropTypes.elementType,
};
export default connect(mapStateToProps, null)(App);