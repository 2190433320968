import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import dpScreenshot from "../../../src/assets/images/dpScreenshot.png";
import { MESSAGES } from "../../constants";

const DIMCard = ({ useCase, selectedData }) => {
  const linkParam =
    selectedData && selectedData.domainId
      ? `teamSpaceId=${selectedData.id}`
      : `domainId=${selectedData?.id}`;

  return (
    <>
      {useCase.isLVTComplete ? (
        <Link
          to={`/dataproduct-interaction-map?useCaseId=${useCase.id}&${linkParam}`}
          className="logo"
          data-testid="DIMCard-link"
        >
          <Card data-testid="DIMCard-card">
            <CardMedia
              sx={{ height: 80 }}
              image={dpScreenshot}
              data-testid="DIMCard-media"
            />
            <CardActions>
              <Button
                sx={{ whiteSpace: "nowrap" }}
                size="small"
                data-testid="DIMCard-button"
              >
                {useCase.description}
              </Button>
            </CardActions>
          </Card>
        </Link>
      ) : (
        <>
          <p data-testid="DIMCard-error" className="DIMCard-card-error-text">
            {MESSAGES.LVT_CONNECTION_NOT_COMPLETED_FOR_USECASE}
          </p>
          <div className="DIMCard-card-noLink-wrapper">
            <Card className="DIMCard-card-noLink" data-testid="DIMCard-card">
              <CardMedia
                sx={{ height: 80 }}
                image={dpScreenshot}
                data-testid="DIMCard-media"
              />
              <CardActions>
                <Button
                  sx={{ whiteSpace: "nowrap" }}
                  size="small"
                  data-testid="DIMCard-button"
                >
                  {useCase.description}
                </Button>
              </CardActions>
            </Card>
          </div>
        </>
      )}
    </>
  );
};

DIMCard.propTypes = {
  useCase: PropTypes.object.isRequired,
  selectedData: PropTypes.object,
};

export default DIMCard;
