import { useEffect, useState } from "react";
import { MarkerType } from "reactflow";
import PropTypes from "prop-types";

const useLineageData = (lineagedata, productData) => {
  const [nodesData, setNodesData] = useState([]);
  const [edgesData, setEdgesData] = useState([]);

  useEffect(() => {
    const inputDatatArray = lineagedata?.data?.inputDataProducts || [];
    const outputDatatArray = lineagedata?.data?.outputDataProducts || [];

    const inputLen = inputDatatArray.length;
    const outputLen = outputDatatArray.length;
    const minHeight = 120;
    const maxHeight = 400;
    let actualHeight = 0;

    if (inputLen >= outputLen && inputLen != 0) {
      const contentHeight = minHeight * inputLen;
      actualHeight = contentHeight > maxHeight ? contentHeight : maxHeight;
    } else if (outputLen != 0) {
      const contentHeight = minHeight * outputLen;
      actualHeight = contentHeight > maxHeight ? contentHeight : maxHeight;
    } else {
      actualHeight = maxHeight;
    }

    const LineageBackgroundSection = [
      {
        id: "input",
        type: "section",
        data: {
          height: actualHeight,
          sectionName: "Input",
        },
        position: { x: 0, y: 0 },
        draggable: false,
        selectable: false,
      },
      {
        id: "default",
        type: "section",
        data: {
          height: actualHeight,
        },
        position: { x: 350, y: 0 },
        draggable: false,
        selectable: false,
      },
      {
        id: "output",
        type: "section",
        data: {
          height: actualHeight,
          sectionName: "Output",
        },
        position: { x: 700, y: 0 },
        draggable: false,
        selectable: false,
      },
    ];

    const userId = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))?.data?.userDetails?.id
      : null;

    let isDpOwner = false;
    //check data product owner
    if (productData.teams != undefined) {
      isDpOwner = productData.teams.some((list) => {
        return (
          list.userId === userId &&
          list.roleId === "bea8408c-425f-409c-a589-f90073f8421a"
        );
      });
    }
    const dataProductNode = [
      {
        id: "middle-node-1",
        type: "hexagon",
        data: {
          type: "default",
          label: productData?.name || "",
          dataProductStatus: productData?.dataProductStatus || "",
          domainId: productData?.domainId,
          domainName: productData?.domainName,
          domainType: productData?.domainType,
          dataProductType: productData?.dataProductType || "",
          isCollibra: productData?.isCollibra,
          rIsRemoved: productData?.isRemoved,
          opacity: 1,
          isDpOwner,
          dataProductId: productData?.id,
        },
        position: { x: 90, y: 40 },
        extent: "parent",
        parentNode: "default",
        draggable: true,
        selectable: true,
      },
    ];
    const inputData = createNodesAndEdges(inputDatatArray, 120, "input");
    const outputData = createNodesAndEdges(outputDatatArray, 70, "output");
    const finalData = [
      ...LineageBackgroundSection,
      ...dataProductNode,
      ...inputData.nodes,
      ...outputData.nodes,
    ];
    const finalEdgesData = [...inputData.edges, ...outputData.edges];
    setNodesData(finalData);
    setEdgesData(finalEdgesData);
  }, [lineagedata, productData]);

  return [nodesData, edgesData];
};

function createNodesAndEdges(data, xaxis, graphType) {
  let inputDataProductLength = data.length;
  let yaxis = 40;
  const edges = [];
  const inputDataProductArray = [];
  for (let i = 0; i < inputDataProductLength; i++) {
    let inputData = data[i];
    inputDataProductArray[i] = {
      id: `${graphType}-node-${i + 1}`,
      type: "hexagon",
      data: {
        type: graphType,
        label: inputData.name,
        dataProductStatus: inputData.dataProductStatus,
        domainId: inputData.domainId,
        domainName: inputData.domainName,
        domainType: inputData.domainType,
        dataProductType: inputData?.dataProductType,
        isCollibra: inputData.isCollibra,
        rIsRemoved: inputData.rIsRemoved,
        opacity: 1,
        isDpOwner: inputData?.isDpOwner || false,
        dataProductId: inputData?.id,
      },
      position: { x: xaxis, y: yaxis },
      draggable: true,
      selectable: true,
      extent: "parent",
      parentNode: graphType,
    };
    const edgesStyles = {
      markerEnd: {
        type: MarkerType.Arrow,
        width: 25,
        height: 25,
        color: inputData?.risCollibraUseCase ? "#5b9d00" : "#000",
        strokeWidth: 1,
      },
      style: {
        strokeWidth: 1.5,
        stroke: inputData?.risCollibraUseCase ? "#5b9d00" : "#000",
      },
    };
    if (graphType === "input") {
      edges[i] = {
        id: `${graphType}-edge-${i + 1}`,
        source: `${graphType}-node-${i + 1}`,
        target: "middle-node-1",
        type: "step",
        data: {
          type: "input",
          domainType: inputData.domainType,
          isDpOwner: inputData?.isDpOwner || false,
          coliibraLineageSource: inputData?.risCollibraUseCase || false,
          domainId: inputData.domainId,
          isCollibra: inputData.isCollibra,
        },
        ...edgesStyles,
      };
    } else if (graphType === "output") {
      edges[i] = {
        id: `${graphType}-edge-${i + 1}`,
        source: "middle-node-1",
        target: `${graphType}-node-${i + 1}`,
        type: "step",
        data: {
          type: "output",
          domainType: inputData.domainType,
          isDpOwner: inputData?.isDpOwner || false,
          coliibraLineageSource: inputData?.risCollibraUseCase || false,
          domainId: inputData.domainId,
          isCollibra: inputData.isCollibra,
        },
        ...edgesStyles,
      };
    }
    yaxis += 100;
  }
  return { nodes: inputDataProductArray, edges: edges };
}

useLineageData.propTypes = {
  lineagedata: PropTypes.object,
  productData: PropTypes.object,
};

export default useLineageData;
