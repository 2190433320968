import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { FaExternalLinkAlt } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { pageStateOptions } from ".";
import { DATAPRODUCT_STATUS_OPTIONS } from "../../constants/DataProductStatusOptions";
import { GO_TO_COLLIBRA_LINK_TEXT } from "../../constants";

const AdditionalOptions = ({
  pageCurrentState,
  dataProductStatus,
  isCollibra,
  dataProductId,
}) => {
  const generateToolTipText = useCallback(
    (buttonIndex) => {
      switch (buttonIndex) {
        case 0:
          return dataProductStatus === DATAPRODUCT_STATUS_OPTIONS.IN_DEVELOPMENT
            ? "This feature will be available in the next release of this application."
            : "You can see more details from Collibra, once it is in development state.";
        case 1:
          return dataProductStatus === DATAPRODUCT_STATUS_OPTIONS.IN_DEVELOPMENT
            ? "This feature will be available in the next release of this application."
            : "Data product is shopable, once it is in development state.";
        default:
          return "";
      }
    },
    [dataProductStatus]
  );

  return (
    <>
      {pageCurrentState === pageStateOptions.DISPLAY ||
      pageCurrentState === pageStateOptions.READY_FOR_IMPLEMENTATION ? (
        <>
          <Divider variant="middle" />

          {isCollibra ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{ margin: "1rem 0" }}
            >
              <Link
                href={process.env.REACT_APP_COLLIBRA_LINK + dataProductId}
                variant="body2"
                color="#979797"
                underline="hover"
                target="_blank"
                rel="noreferrer"
                fontSize={16}
              >
                {GO_TO_COLLIBRA_LINK_TEXT}
              </Link>
              <Link
                href={`https://rdmportal.roche.com/rdmportal/#/asset/${dataProductId}?viewMode=DEFAULT`}
                variant="body2"
                color="#979797"
                underline="hover"
                target="_blank"
                rel="noreferrer"
                fontSize={16}
              >
                Shop Data Product
              </Link>
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{ margin: "1rem 0" }}
            >
              <Tooltip
                title={generateToolTipText(0)}
                placement="bottom-end"
                data-testid="collibraLink"
                arrow
              >
                <Link
                  href="#"
                  variant="body2"
                  color="#979797"
                  underline="hover"
                  fontSize={16}
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  {GO_TO_COLLIBRA_LINK_TEXT}
                  <FaExternalLinkAlt className="external-link-icon" />
                </Link>
              </Tooltip>
              <Tooltip
                title={generateToolTipText(1)}
                placement="bottom-end"
                data-testid="shopableLink"
                arrow
              >
                <Link
                  href="#"
                  variant="body2"
                  color="#979797"
                  underline="hover"
                  fontSize={16}
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  Shop Data Product
                  <FaExternalLinkAlt className="external-link-icon" />
                </Link>
              </Tooltip>
            </Stack>
          )}
        </>
      ) : null}
    </>
  );
};

AdditionalOptions.propTypes = {
  pageCurrentState: PropTypes.string.isRequired,
  dataProductStatus: PropTypes.string,
  isCollibra: PropTypes.bool,
  dataProductId: PropTypes.string,
};

export default AdditionalOptions;
