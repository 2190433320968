import { LINEAGE_FILTER_CONST } from "./actionTypes";

export const getSourceFilterData = (key, value) => {
  return {
    type: LINEAGE_FILTER_CONST.SOURCE_FILTER,
    payload: { key, value },
  };
};

export const getShowFilterData = (key, value) => {
  return {
    type: LINEAGE_FILTER_CONST.SHOW_FILTER,
    payload: { key, value },
  };
};

export const getProductOwner = (key, value) => {
  return {
    type: LINEAGE_FILTER_CONST.PRODUCT_OWNER,
    payload: { key, value },
  };
};

export const getDomainsFilter = (data) => {
  return {
    type: LINEAGE_FILTER_CONST.DOMAIN_FILTER,
    payload: data,
  };
};

export const resetFilter = () => {
  return {
    type: LINEAGE_FILTER_CONST.RESET_FITLER,
    payload: {},
  };
};
