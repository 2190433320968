import {
  TO_BE_DECIDED,
  DRAFT,
  PHARMA,
  DIAGNOSTICS,
} from "../../../constants/DataDomainType";
import { LINEAGE_FILTER_CONST } from "./actionTypes";

const initialState = {
  sourceFilterData: {
    [TO_BE_DECIDED]: true,
    [PHARMA]: true,
    [DIAGNOSTICS]: true,
    [DRAFT]: true,
  },
  showFilterData: {
    Input: true,
    Output: true,
  },
  ownerList: {
    productOwner: false,
  },
  domainFilter: {},
};

const LineageFilter = (state = initialState, action) => {
  switch (action.type) {
    case LINEAGE_FILTER_CONST.SOURCE_FILTER:
      state.sourceFilterData[action.payload.key] = action.payload.value;
      return {
        ...state,
      };
    case LINEAGE_FILTER_CONST.SHOW_FILTER:
      state.showFilterData[action.payload.key] = action.payload.value;
      return {
        ...state,
      };
    case LINEAGE_FILTER_CONST.PRODUCT_OWNER:
      state.ownerList[action.payload.key] = action.payload.value;
      return {
        ...state,
      };
    case LINEAGE_FILTER_CONST.RESET_FITLER:
      Object.keys(state.sourceFilterData).forEach((key) => {
        state.sourceFilterData[key] = true;
      });
      Object.keys(state.showFilterData).forEach((key) => {
        state.showFilterData[key] = true;
      });
      Object.keys(state.ownerList).forEach((key) => {
        state.ownerList[key] = false;
      });
      Object.keys(state.domainFilter).forEach((key) => {
        state.domainFilter[key] = true;
      });
      return {
        ...state,
      };
    case LINEAGE_FILTER_CONST.DOMAIN_FILTER:
      return {
        ...state,
        domainFilter: { ...state.domainFilter, ...action.payload },
      };
    default:
      state = {
        sourceFilterData: {
          [TO_BE_DECIDED]: true,
          [PHARMA]: true,
          [DIAGNOSTICS]: true,
          [DRAFT]: true,
        },
        showFilterData: {
          Input: true,
          Output: true,
        },
        ownerList: {
          productOwner: false,
        },
        domainFilter: {},
      };
      break;
  }
  return state;
};

export default LineageFilter;
