import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import "../../../assets/scss/diagram.scss";
import { getEnrolledDomains } from "../../../store/actions";
import {
  AXIOS_INSTANCE,
  LEAN_VALUE_TREE_API,
} from "../../../store/apiUtils/config";
import { GlobalNotificationHandle } from "../../../services/NotificationHandler";
import useModal from "../../../helpers/CustomHooks/useModal";
import LVTDiagram from "./LVTDiagram";
import LVTHeader from "./LVTHeader";
import LVTMenu from "./LVTMenu";
import { LVT_ITEM } from "./LVTDiagramSettings";
import AddUserToDomainModal from "../../DomainDataProducts/AddUserToDomainModal";
import { socket } from "../../../services/Socket";

const LeanValueTree = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { isCreate, createToggle } = useModal();
  const history = useHistory();
  const [initialNodes, setInitialNodes] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState({});
  const [selectedTeamSpace, setSelectedTeamSpace] = useState({});
  const [initialEdges, setInitialEdges] = useState([]);
  const [userEditAccess, setUserEditAccess] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [useCasesArray, setUseCasesArray] = useState([]);
  const [activeStep, setActiveStep] = useState(-1);
  const params = new URLSearchParams(search);
  const domainIdParam = useMemo(() => params.get("domainId"), [params]);
  const teamSpaceId = useMemo(() => params.get("teamSpaceId"), [params]);
  const userDetails = localStorage.getItem("loginInfo");
  const isAdmin = JSON.parse(userDetails)?.data?.userDetails?.isAdmin;
  const [TeamSpaceData, setTeamSpaceData] = useState([]);
  const fetchExistingNodes = useCallback(
    async ({ domainId, teamSpaceId }) => {
      try {
        let data;
        if (domainId) {
          const results = await AXIOS_INSTANCE.get(
            `${LEAN_VALUE_TREE_API}/getAllByDomainId`,
            { params: { domainId } }
          );
          data = results.data;
        } else if (teamSpaceId) {
          const results = await AXIOS_INSTANCE.get(
            `${LEAN_VALUE_TREE_API}/getAllByTeamSpaceId`,
            { params: { teamSpaceId } }
          );
          data = results.data;
        }

        const finalData =
          data?.data?.data?.map((e) => ({
            ...e,
            type: LVT_ITEM,
            parentNode: e.data.step,
            extent: "parent",
          })) || [];

        setInitialNodes([...finalData]);
      } catch (e) {
        GlobalNotificationHandle(
          e.response.status,
          e.response.data.errorDescription,
          false,
          true
        );
      }
    },
    [setInitialNodes]
  );

  const refreshUseCases = useCallback(() => {
    if (reactFlowInstance) {
      const allNodes = reactFlowInstance.getNodes() || [];
      const useCaseNodes =
        allNodes.filter(
          (node) => node.type === "lvtItem" && node.data.step === "useCase"
        ) || [];
      setUseCasesArray(useCaseNodes);
    }
  }, [reactFlowInstance, setUseCasesArray]);

  const fetchExistingEdges = useCallback(
    async ({ domainId, teamSpaceId }) => {
      try {
        let data;
        if (domainId) {
          const results = await AXIOS_INSTANCE.get(
            `${LEAN_VALUE_TREE_API}/getAllHasLvtConnectionsByDomainId`,
            { params: { domainId } }
          );
          data = results.data;
        } else if (teamSpaceId) {
          const results = await AXIOS_INSTANCE.get(
            `${LEAN_VALUE_TREE_API}/getAllHasLvtConnectionsByTeamSpaceId`,
            { params: { teamSpaceId } }
          );
          data = results.data;
        }
        const finalData = [...(data?.data?.data || [])];

        setInitialEdges(finalData);
      } catch (e) {
        GlobalNotificationHandle(
          e.response.status,
          e.response.data.errorDescription,
          false,
          true
        );
      }
    },
    [setInitialEdges]
  );

  const fetchLVTStatus = useCallback(
    async ({ domainId, teamSpaceId }) => {
      try {
        let data;
        if (domainId) {
          const results = await AXIOS_INSTANCE.get(
            `${LEAN_VALUE_TREE_API}/getDomainDiscoveredByDomainId?domainId=${domainId}`
          );
          data = results.data;
        } else if (teamSpaceId) {
          const results = await AXIOS_INSTANCE.get(
            `${LEAN_VALUE_TREE_API}/getLVTDiscoveredByTeamSpaceId?teamSpaceId=${teamSpaceId}`
          );
          data = results.data;
        }

        if (data?.data?.data?.LVTDiscovered) setActiveStep(1);
        else setActiveStep(0);
        return data?.data?.data?.LVTDiscovered;
      } catch (e) {
        GlobalNotificationHandle(
          e.response.status,
          e.response.data,
          false,
          true
        );
      }
    },
    [setActiveStep]
  );

  useEffect(() => {
    localStorage.setItem("hideLoader", true);

    return () => {
      localStorage.removeItem("hideLoader");
    };
  });

  useEffect(() => {
    if (domainIdParam) {
      socket.lastJoinedRoom = `LVT-${domainIdParam}`;
      socket.emit("joinRoom", socket.lastJoinedRoom);
      socket.on("receiveDomainData", setSelectedDomain);
    } else if (teamSpaceId) {
      socket.lastJoinedRoom = `LVT-${teamSpaceId}`;
      socket.emit("joinRoom", socket.lastJoinedRoom);
      socket.on("receiveTeamSpaceData", setSelectedTeamSpace);
    }
    return () => {
      socket.emit("leaveRoom", socket.lastJoinedRoom);
      socket.lastJoinedRoom = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (domainIdParam) {
      socket.emit(
        "getDomainById",
        { domainId: domainIdParam },
        socket.lastJoinedRoom
      );
      dispatch(getEnrolledDomains());
    } else if (teamSpaceId) {
      socket.emit("getTeamSpaceById", { teamSpaceId }, socket.lastJoinedRoom);
    }
  }, [dispatch, domainIdParam, teamSpaceId]);

  useEffect(() => {
    if (teamSpaceId) {
      socket.emit("getAllEnrolledTeamSpaces");
      socket.on("receiveAllEnrolledTeamSpaces", (data) => {
        setTeamSpaceData(data?.enrolledTeamSpaceList);
      });
    }
  }, [teamSpaceId]);

  const { domainList } = useSelector((state) => ({
    domainList: state.Domains.enrolledDomainsList,
  }));

  useEffect(() => {
    if (!domainIdParam && !teamSpaceId) {
      history.push("/home");
    }
  }, [domainIdParam, teamSpaceId, history]);

  useEffect(() => {
    if (selectedDomain?.id) {
      fetchExistingNodes({ domainId: selectedDomain?.id });
      fetchExistingEdges({ domainId: selectedDomain?.id });
      fetchLVTStatus({ domainId: selectedDomain?.id });
    } else if (selectedTeamSpace?.id) {
      fetchExistingNodes({ teamSpaceId: selectedTeamSpace?.id });
      fetchExistingEdges({ teamSpaceId: selectedTeamSpace?.id });
      fetchLVTStatus({ teamSpaceId: selectedTeamSpace?.id });
    }
  }, [
    selectedDomain,
    selectedTeamSpace,
    fetchExistingNodes,
    fetchExistingEdges,
    fetchLVTStatus,
  ]);

  useEffect(() => {
    let userEnrolledToSelectedData;
    if (selectedDomain?.id && domainList) {
      userEnrolledToSelectedData = domainList.find(
        (domainDetails) => domainDetails.id === selectedDomain.id
      );
    } else if (selectedTeamSpace?.id && TeamSpaceData) {
      userEnrolledToSelectedData = TeamSpaceData.find(
        (teamSpaceDetails) => selectedTeamSpace.id === teamSpaceDetails.id
      );
    }
    setUserEditAccess(
      userEnrolledToSelectedData !== undefined || isAdmin ? true : false
    );
  }, [isAdmin, domainList, selectedDomain, selectedTeamSpace, TeamSpaceData]);
  return (
    <div className="global-top-1" style={{ background: "#fff" }}>
      <LVTMenu userEditAccess={userEditAccess} />
      <LVTHeader
        selectedData={
          Object.keys(selectedDomain)?.length === 0
            ? selectedTeamSpace
            : selectedDomain
        }
        useCasesArray={useCasesArray}
        setUseCasesArray={setUseCasesArray}
        activeStep={activeStep}
        isTeamSpace={teamSpaceId ? true : false}
      />
      <div
        style={{
          height: "90vh",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <LVTDiagram
          initialNodes={initialNodes}
          initialEdges={initialEdges}
          userEditAccess={userEditAccess}
          reactFlowInstance={reactFlowInstance}
          setReactFlowInstance={setReactFlowInstance}
          refreshUseCases={refreshUseCases}
          fetchLVTStatus={fetchLVTStatus}
        />
      </div>
      {!selectedDomain?.isSandbox && (
        <IconButton
          className="add-domain-member"
          size="large"
          color="primary"
          onClick={createToggle}
        >
          <PersonAddAlt1Icon />
        </IconButton>
      )}
      {isCreate ? (
        <AddUserToDomainModal
          domainId={selectedDomain?.id}
          teamSpaceId={selectedTeamSpace?.id}
          toggle={createToggle}
        />
      ) : null}
    </div>
  );
};
export default LeanValueTree;
