import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, useHistory, Link } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { FaQuestionCircle } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Auth } from "aws-amplify";
import { t } from "react-multi-lang";
import { getUserData } from "../../helpers";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import AvatarIcon from "../../assets/images/avatar-icon.png";
import "./ProfileMenu.scss";

const ProfileMenu = () => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleReport = () => setReportOpen((prevState) => !prevState);
  const { userDetails } = getUserData();

  const handleLogout = () => {
    Auth.signOut();
    localStorage.clear();
    history.push("/login");
  };

  return (
    <React.Fragment>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ marginRight: "2rem" }}
      >
        <Dropdown
          isOpen={reportOpen}
          toggle={toggleReport}
          className="profile-dropdown d-inline-block"
          data-testid="support-dropdown"
        >
          <DropdownToggle className="m-0 p-0">
            <span className="d-inline-flex align-items-center header-text-buttons">
              Support
              <FaQuestionCircle className="support-link-icon" />
            </span>
          </DropdownToggle>
          {/* <DropdownMenu className="profile-dp-menu-left"> */}
          <DropdownMenu>
            <Link
              to={{
                pathname:
                  "https://roche.service-now.com/icare?id=sc_cat_item&sys_id=a5c462af875952149508c8480cbb3524",
              }}
              target="_blank"
            >
              <DropdownItem className="profile-dropdown-item">
                <div className="dropdown-item-content">
                  Request a support ticket
                  <FaExternalLinkAlt className="external-link-icon-support" />
                </div>
              </DropdownItem>
            </Link>
            <Link
              to={{
                pathname:
                  "https://roche.service-now.com/icare?id=sc_cat_item&sys_id=2958cf76871dd6901f3c63560cbb3513",
              }}
              target="_blank"
            >
              <DropdownItem className="profile-dropdown-item">
                <div className="dropdown-item-content">
                  Request an incident ticket
                  <FaExternalLinkAlt className="external-link-icon-support" />
                </div>
              </DropdownItem>
            </Link>
          </DropdownMenu>
        </Dropdown>

        <Link to="/faq">
          <span className="d-inline-block header-text-buttons">
            Glossary & FAQ
          </span>
        </Link>
        <Link to="/releases">
          <span className="d-inline-block header-text-buttons">Releases</span>
        </Link>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="profile-dropdown d-inline-block"
          data-testid="profile-dropdown"
        >
          <DropdownToggle className="m-0 p-0">
            <img src={AvatarIcon} alt="avatar-icon" data-testid="avatar-icon" />
          </DropdownToggle>
          {/* <DropdownMenu className="profile-dp-menu-right" right> */}
          <DropdownMenu right>
            {userDetails?.isAdmin ? (
              <Link
                to={{
                  pathname: "/admin-details",
                }}
                target="_blank"
                data-testid="admin-link"
              >
                <DropdownItem className="profile-dropdown-item">
                  <div className="vertical-alignment">Admin Panel</div>
                </DropdownItem>
              </Link>
            ) : null}
            <DropdownItem
              className="profile-dropdown-item"
              onClick={handleLogout}
              data-testid="logout-button"
            >
              <div className="vertical-alignment">
                <AiOutlineLogout className="float-left mr-2 red-color" />
                {t("LOGIN.Logout")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Stack>
    </React.Fragment>
  );
};

export default withRouter(ProfileMenu);
